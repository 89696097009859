import { IEnvironment } from '@env/environment.model';

export const environment: IEnvironment = {
  production: true,
  config: {
    logs: {
      // required config
      collector:
        'ZaVnC4dhaV1HAyCHkJkwzmAZSLLbA9rXjkMN_Fd7Jdp3lmv6g8VZwDndnUlasGedwtitE6c7Riq83T5H0D-tlYaP6rMUlAunK5ODeDUCExo9ynMkCaRxjg==',
      // optional config
      endpoint: 'https://collectors.au.sumologic.com/receiver/v1/http/',
      level: 'error',
    },
    layout: {
      renderer: 'https://renderer.test.ca.leap.services',
    },
    cache: {
      roleARN: 'arn:aws:iam::438555434192:role/redis-shared-parameterstore-ca-central-1',
      sessionName: 'leap365-sirius',
      parameterStoreKey: 'redis-shared-connection',
      awsRegion: 'ca-central-1',
    },
    brand: {
      analytics: 'GTM-MD8QMN6',
      id: 'test.leap365.ca',
      name: 'Leap',
      leapcalc: 'leap',
      env: 'test',
      region: 'ca',
      helpUrl: 'https://community.leaplegalsoftware.ca',
      termsUrl: 'https://leaplegalsoftware.ca', // no terms url currently for CA, just use baseUrl.
      privacyUrl: 'https://leaplegalsoftware.ca/privacy-statement',
      brandUrl: 'test.leap365.ca',
      bylawyersUrl: 'https://leaplegalsoftware.ca/companion-products/bylawyers',
      copyrightName: 'LEAP Software Developments Pty. Limited',
    },
    precedent: {
      letterContainerId: '3008',
    },
    support: {
      communityUrl: 'https://leap--community.sandbox.my.site.com/cahelpcentre/s',
      liveChatUrl: 'https://secure.livechatinc.com/licence/4997331/v2/open_chat.cgi',
      feedbackUrl: 'https://uxform.typeform.com/to',
      feedbackCode: 'XbNsAv',
    },
    oauth: {
      scopes: '*',
      endpoint: 'https://login.test.leap365.ca',
      user_info_uri: '/api/userinfo',
      provider_id: 'LEO',
      client_id: 'HNWRWDUUJ9SRSAQD',
      uniqueSessionActive: true,
      autoLogoutActive: false,
      userPresence: true,
      backendClientId: 'SNAXWYYI1RRKYGT2',
    },
    automation: {
      uriSchema: 'leapcatest',
    },
    keys: {
      pnSubscribe: 'sub-a456f002-0095-11e2-9638-9581afc33ebf',
    },
    apps: {
      glLink: 'https://test-gllinkapp.leapaws.com',
      marketPlace: 'https://test.marketplace.leap.build/apps',
      lawconnect: 'https://lawconnect.test.leap365.ca',
      appIds: {
        infotrack: [],
        timesheet: ['75e24f87-0e36-43b3-9fb3-58c00605f8e7', '57096634-5086-4c7e-a7fc-627fc728ad76'],
      },
      appFunctions: {
        timesheet: ['44c84f9d-3072-4e77-8de7-57e351ae7bc8'],
      },
    },
    endpoint: {
      account: 'https://account2-api.test.ca.leap.services',
      accounting: 'https://api-offexaccounting.test.leapaws.ca',
      accountingv2: 'https://acc-api.test.leapaws.ca',
      adminLawConnect: 'https://admin.test.ca.lawconnect.com',
      appConsole: 'https://api.test.console.leap.build',
      automation: 'https://office-automation-api.test.leapaws.ca',
      bankrec: 'https://bankrec.test.leap365.ca',
      bylawyers: 'https://test.bylawyers.ca',
      calendar: 'https://superdiary-api.test.leapaws.ca',
      cdn: 'https://cdn.leap.ca',
      docs: 'https://api-docs.test.leapaws.ca',
      docsPublic: 'https://api-docs-public.test.leapaws.ca',
      docBuilder: 'https://doc-builder.test.leapaws.ca',
      infotrack: 'https://stagesearch.infotrack.com',
      trisearch: 'https://stagesearch.infotrack.com',
      leaponline: 'https://updates.test.leapaws.ca/leap-online/public/LEAPWebInstaller.exe',
      myobweb: '',
      pdfservice: 'https://pdfservice.test.leapaws.ca',
      quickbook: 'https://quickbooksweb.test.leapaws.ca',
      reporting: 'https://reporting.test.leapaws.ca',
      reportingApi: 'https://reporting-api.test.leapaws.ca',
      schema: 'https://schema-api.test.ca.leap.services',
      wopi: 'https://office-wopi-api.test.leapaws.ca',
      xero: 'https://xeroweb.test.leapaws.ca',
      auth: 'https://login.test.leap365.ca',
      globalAuth: 'https://auth-test.leap.services',
      options: 'https://acc-options-api.test.leapaws.ca',
      options_app: 'https://settings.test.leap365.ca',
      leapadin: 'https://leapaddins-api.test.leapaws.ca',
      listaddin: 'https://updates-leapaws-ca.s3.amazonaws.com/1/test/x64/matteraddin/api-listV3.json',
      notifications: 'https://notificationsapi.test.leapaws.ca',
      lcintegrator: 'https://lcintegrator.test.leapaws.ca',
      phoenix: 'https://guides.test.bylawyers.ca',
      msteams: 'https://teams-test.leapaws.co.uk',
      content: 'https://api-contentgateway.test.leapaws.ca',
      docsV2: 'https://api-docs-core.test.leapaws.ca',
      leapDesign: 'https://leapdesign.leapaws.ca/ca/test',
    },
    aws: {
      reportingBucket: 'sirius-transactions-test-leapaws-ca',
      region: 'ca-central-1',
      paramStorePath: '/officecloud/leap365/',
      accountId: '064865921863',
    },
    ksd: {
      enabled: true,
      configurations: [
        {
          protocol: 'https',
          method: '*',
          domain: `api-docs.test.leapaws.ca`,
          path: '/api/*',
        },
      ],
    },
    esignature: {
      enabled: false,
    },
    invoiceSharing: {
      enabled: false,
    },
    featureFlag: {
      client_id: '6655571aaba81f105a9219d3',
    },
    auth0: {
      domain: 'leapauthtest.au.auth0.com',
      clientId: 'FM6QHtgu9Ftwrn5HBs0XKjADVMX74IzV',
    },
  },
};
